import * as React from "react"
import Seo from "../../components/seo.js"
import ContentPageLayout from "../../components/ContentPageLayout.js"
import ContentSkeleton from "../../components/ContentSkeleton.js"
import {
  getCategoryContents,
  getCategoryInfo,
  getAllSections,
} from "../../api/content.js"
import SearchBlock from "../../components/content/searchBlock.js"
import config, { firebaseInit } from "../../config.js"
import firebase from "firebase"
import ChatButton from "../../components/buttons/ChatButton.js"
import { Helmet } from "react-helmet"
import FilterSearchButton from "../../components/buttons/FilterSearchButton.js"
import SignInModal from "../../components/modals/SignInModal.js"
import PaymentMethodModal from "../../components/modals/PaymentMethodModal.js"
import NewPaymentFormModal from "../../components/modals/NewPaymentFormModal.js"
import SubscriptionMessageModal from "../../components/modals/SubscriptionMessageModal.js"
import CvvModal from "../../components/forms/payments/cvvModal.js"
import PerfilForm from "../../components/modals/PerfilFormModal.js"
import PremiumButton from "../../components/buttons/PremiumButton.js"
import BackButton from "../../components/buttons/BackButton.js"
import { FaSearch, FaTimes } from "react-icons/fa"
import CategoryBlock from "../../components/content/categoryBlock.js"

const Category = ({ location, params }) => {
  const profileUri = config.serverUri
  const [contentLoading, setContentLoading] = React.useState(true)
  const [section, setSection] = React.useState(null)
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [filteredCards, setFilteredCards] = React.useState([])
  const [searchVisible, setSearchVisible] = React.useState(false) // controla la visibilidad del input
  const [searchText, setSearchText] = React.useState("") // guarda el texto de búsqueda
  const [SectionFiltrada, setSectionFiltrada] = React.useState(null)
  const [showSignInForm, setShowSignInForm] = React.useState(false)
  const [showPaymentMethod, setShowPaymentMethod] = React.useState(false)
  const [showNewPayment, setShowNewPayment] = React.useState(false)
  const [showSuccessful, setShowSuccessful] = React.useState(false)
  const [showCvvHelp, setShowCvvHelp] = React.useState(false)
  const [showPerfilForm, setShowPerfilForm] = React.useState(false)
  const [ispwa, setIspwa] = React.useState(null)
  const [isApplePay, setIsApplePay] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [categoryName, setCategoryName] = React.useState(null)
  const [categoryInfo, setCategoryInfo] = React.useState(null)
  const [noResults, setNoResults] = React.useState(false) // Estado para mostrar el mensaje sin resultados

  const categoryId = params.id

  const toggleSearch = () => {
    setSearchVisible(!searchVisible)
    setSearchText("")
    setNoResults(false) // Restablece el mensaje sin resultados al cerrar la búsqueda

    // Restaurar los bloques originales en filteredCards
    if (SectionFiltrada && Array.isArray(SectionFiltrada.blocks)) {
      setFilteredCards(SectionFiltrada.blocks)
    }
  }

  const handleSearch = () => {
    if (SectionFiltrada && Array.isArray(SectionFiltrada.blocks)) {
      if (searchText.trim() === "") {
        // Si no hay texto de búsqueda, mostrar todos los bloques
        setFilteredCards(SectionFiltrada.blocks)
        setNoResults(false)
      } else {
        // Filtrar bloques basados en el texto de búsqueda
        const filteredBlocks = SectionFiltrada.blocks.filter(
          block =>
            block.cards &&
            Array.isArray(block.cards) &&
            block.cards.length > 0 &&
            block.cards.some(card =>
              card.titulo.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        setFilteredCards(filteredBlocks)
        setNoResults(filteredBlocks.length === 0)
      }
    } else {
      console.error("SectionFiltrada.blocks no es un arreglo válido")
      setFilteredCards([])
      setNoResults(true)
    }
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  React.useEffect(() => {
    setContentLoading(true)
    getAllSections().then(data => {
      setSection(data)
      setContentLoading(false)
    })
  }, [])

  React.useEffect(() => {
    setIsLoading(true)
    getCategoryInfo(categoryId)
      .then(data => {
        console.log(data)
        setCategoryInfo(data)
        setCategoryName(data.title)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error al obtener los datos de la categoría:", error)
        setIsLoading(false)
      })
    getCategoryContents(categoryId)
      .then(data => {
        setSectionFiltrada(data)
        // Inicializar filteredCards y noResults cuando se carguen los datos
        if (data.blocks && Array.isArray(data.blocks)) {
          setFilteredCards(data.blocks)
          setNoResults(false)
        }
        setIsLoading(false)
      })
      .catch(error => {
        console.error("Error al obtener los datos de la categoría:", error)
        setIsLoading(false)
      })
  }, [categoryId])
  

  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const response = await fetch(`${profileUri}/users/?email=${user.email}`)
          if (response.ok) {
            const dataUser = await response.json()
            setUserHasSuscription(dataUser[0]?.suscription || false)
          } else {
            console.error("Error al obtener la suscripción del usuario")
            setUserHasSuscription(false)
          }
        } catch (error) {
          console.error("Error al validar la suscripción:", error)
          setUserHasSuscription(false)
        }
      } else {
        console.log("Usuario no autenticado")
        setUserHasSuscription(false)
      }
    })
  }, [])
  

  React.useEffect(() => {
    const updateImageHeight = () => {
      if (!categoryInfo) {
        return
      }
      if (categoryInfo.desktopImage) {
        const aspectRatio =
          categoryInfo.desktopImage.height / categoryInfo.desktopImage.width
        setImageHeight(window.innerWidth * aspectRatio)
      }
    }
    console.log(categoryInfo?.desktopImage)

    // Ajusta la altura al cargar y al redimensionar la ventana
    updateImageHeight()
    window.addEventListener("resize", updateImageHeight)

    // Limpia el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", updateImageHeight)
    }
  }, [categoryInfo])

  const [imageHeight, setImageHeight] = React.useState("auto")

  const suscription = text => {
    text === "premium" || text === "free"
      ? setUserHasSuscription(text)
      : setUserHasSuscription(false)
  }

  const handleFilter = nuevoBloque => {
    setFilteredCards(nuevoBloque)
  }

  return (
    <ContentPageLayout
      suscription={suscription}
      location={location}
      section={section}
    >
      <Helmet>
        <script>
          {`!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};ttq.load('CH4N0RRC77UEFTNV1RUG');ttq.page();}(window, document, 'ttq');`}
        </script>
      </Helmet>
      <Seo title={`Category - ${categoryName}`} />
      <ChatButton />

      {contentLoading || !section ? (
        <ContentSkeleton />
      ) : (
        <div>
          {categoryInfo && !contentLoading ? (
            <header>
              <div>
                <div className="flex p-4">
                  <BackButton fontSize={30} />

                  {/* Botón de lupa e input de búsqueda */}
                  <div className="ml-auto flex items-center justify-end">
                    {!searchVisible ? (
                      <FaSearch
                        fontSize={18}
                        className="cursor-pointer"
                        onClick={() => setSearchVisible(true)}
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          onKeyDown={handleKeyDown}
                          placeholder="Buscar..."
                          className="bg-transparent border-b focus:outline-none pr-4" // Fondo transparente, subrayado y padding derecho
                        />
                        <FaTimes
                          fontSize={18}
                          className="cursor-pointer ml-2"
                          onClick={toggleSearch}
                        />
        
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="relative bg-white"
                  style={{ width: "100%", height: `${imageHeight}px` }}
                >
                  <img
                    className="absolute"
                    src={
                      categoryInfo.desktopImage.url.trim().startsWith("http")
                        ? categoryInfo.desktopImage.url.trim()
                        : config.serverUri +
                          categoryInfo.desktopImage.url.trim()
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "20px", // Cambia 20px por el valor de redondeo que prefieras
                    }}
                    alt={categoryInfo.title}
                  />

                  {/* Capa de superposición con el color y opacidad de categoryInfo */}
                  <div
                    className="absolute inset-0"
                    style={{
                      backgroundColor: categoryInfo.color,
                      opacity: 0.6,
                      borderRadius: "20px",
                    }}
                  ></div>

                  <div className="absolute inset-0 flex justify-center items-center">
                    {/* Título centrado */}
                    <h1 className="text-white text-center text-4xl font-bold">
                      {categoryInfo.title}
                    </h1>
                  </div>

                  {section.nombre === "Search" &&
                  userHasSuscription !== "premium" ? (
                    <div
                      className="absolute flex justify-center"
                      style={{ height: 240, width: "100%" }}
                    >
                      <div className="flex flex-col justify-items-stretch content-end h-full p-8 mt-2">
                        <p className="text-white text-center text-2xl mb-0">
                          Prueba gratis por 7 días
                        </p>
                        <p className="text-white text-center text-lg mb-0">
                          Disfruta de contenido ilimitado. ¿Qué esperas?
                        </p>
                        <PremiumButton
                          href="#"
                          onClick={() => {
                            userHasSuscription !== "free" &&
                            userHasSuscription !== null
                              ? setShowSignInForm(true)
                              : setShowPaymentMethod(true)
                          }}
                        >
                          Hazte premium
                        </PremiumButton>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </header>
          ) : null}

          {SectionFiltrada && !contentLoading ? (
            <>
              <main className="p-8 mb-32">
                {noResults ? (
                  <p>No hay elementos que coincidan con la búsqueda.</p>
                ) : (
                  <>
                    {filteredCards.length > 0 ? (
                      filteredCards.map(block => (
                        <CategoryBlock
                          key={block._id}
                          block={block}
                          hideOverlayAndImage={true}
                          idCategory={categoryId}
                          userHasSuscription={userHasSuscription}
                        />
                      ))
                    ) : (
                      <p>No hay elementos que coincidan con la búsqueda.</p>
                    )}
                  </>
                )}
              </main>

              <SignInModal
                show={showSignInForm}
                onClose={() => setShowSignInForm(false)}
              />
              <PaymentMethodModal
                show={showPaymentMethod}
                onClose={() => setShowPaymentMethod(false)}
                newPaymentMethod={value => {
                  setShowNewPayment(value)
                  setShowPaymentMethod(false)
                }}
                message={value => {
                  setShowSuccessful(value)
                  suscription("premium")
                  setShowPaymentMethod(false)
                }}
                setIsApplePay={setIsApplePay}
              />
              <NewPaymentFormModal
                show={showNewPayment}
                onClose={() => setShowNewPayment(false)}
                back={() => {
                  setShowPaymentMethod(true)
                  setShowNewPayment(false)
                }}
                cvvHelp={() => setShowCvvHelp(true)}
                messageOk={value => {
                  suscription("premium")
                  setShowSuccessful(value)
                  setShowNewPayment(false)
                }}
              />
              <SubscriptionMessageModal
                isApplePay={isApplePay}
                show={showSuccessful}
                onClose={() => {
                  setShowSuccessful(false)
                  setShowPerfilForm(true)
                }}
              />
              <CvvModal
                show={showCvvHelp}
                onClose={() => setShowCvvHelp(false)}
              />
              {!ispwa && userHasSuscription === "premium" ? (
                <PerfilForm
                  show={showPerfilForm}
                  ispwaComponent={ispwa}
                  onClose={() => setShowPerfilForm(false)}
                />
              ) : null}
            </>
          ) : (
            <main className="p-8 mb-32">
              <h1>No hay contenido en esta categoría</h1>
            </main>
          )}
        </div>
      )}
    </ContentPageLayout>
  )
}

export default Category
